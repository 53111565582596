import $ from 'jquery'
const $win = $( window );
const $doc = $( document );

const equalizeHeight = () => {
    
	$win.on( 'load resize', function() {
		setTimeout(function() {
			$( '.section--services .single__service .entry' ).equalizeHeight();
			$( '.services--lists li ' ).equalizeHeight();
			$( '.service--single .services__list .single__service' ).equalizeHeight();
		}, 10);
	});

	$.fn.equalizeHeight = function() {
		let maxHeight = 0, itemHeight;

		this.css( 'height', '' );

		for ( let i = 0; i < this.length; i++ ) {
			itemHeight = $( this[i] ).height();
			if ( maxHeight < itemHeight ) {
				maxHeight = itemHeight;
			}
		}

		return this.height( maxHeight );
	}
};

export default equalizeHeight;
