import $ from "jquery";
const $win = $(window);
const $body = $("body");
const $fbar_action_links = $('.footerbar-links li');
const $fbar_location_links = $('.footerbar-locations li a');
const $fbar_wrapper = $('.footerbar-location-wrapper');
const $fbar_closer = $('.footerbar-closer');
const $fbar_closer_objs = $.map([$fbar_location_links, $fbar_closer], function(el){return el.get()});
var lastPos = 0;
var scrollPos = 0;

const mobileInfoBar = () => {

    // Display on Scrol for Touch Devices
    $win.on("load scroll", function(e) {

        // check if mobile
        if  ( 'ontouchstart' in document.documentElement ) {
            
            scrollPos = $win.scrollTop();
            if ( scrollPos == 0 || scrollPos < lastPos ) {
                $body.addClass("show-infobar");
            } else if (scrollPos >= 0 ) {
                $body.removeClass("show-infobar");
                $fbar_wrapper.removeClass('show-locations');
            }
            lastPos = scrollPos;

        } 
    });

    $fbar_action_links.on('click', function() {
        $fbar_wrapper.addClass('show-locations');
        $(this).addClass('selected');
        $fbar_action_links.not(this).removeClass('selected');
        let action_type = $(this).data('action');
        $fbar_location_links.each(function( index ) {
            $(this).attr('href', $(this).data(action_type) );
            $(this).attr('target', ( 'map' == action_type ) ? '_blank' : '_self' );
        });
    });

    $($fbar_closer_objs).on('click', function() {
        $fbar_wrapper.removeClass('show-locations');
        $fbar_action_links.removeClass('selected');
    });

};

export default mobileInfoBar;
