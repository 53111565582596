import $ from 'jquery';

const $win = $( window );
const $doc = $( document );

const onLoadEvents = () => {
	$win.on( 'load resize orientationchange', function() {
		$('.section--feed li').each(function() {
			let elementWidth = $(this).width();
			$(this).css('max-height', elementWidth)
		})

		let nextElementHeight = $('.section--feed li.large__post').next().height()
		$('.section--feed li.large__post').css('height', nextElementHeight)

        // reposition Treatment titles
        let $txTitleBlock = $( 'body.page-template-sub-service .intro-content' );
        let $txTitleText  = $( 'body.page-template-sub-service .intro-title' );
        let $txContentBox = $( 'body.page-template-sub-service .intro-bullets' );
        if ( $txTitleBlock.length && $txTitleText.length ) {
            let txTitleHeight =  $txTitleText.outerHeight();
            $txTitleBlock.css( 'margin-top', 0 - txTitleHeight );
            $txContentBox.css( 'margin-top', txTitleHeight );
        }

	});
};

export default onLoadEvents;