var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "recent-posts-wrapper" }, [
    _c(
      "div",
      { staticClass: "recent-posts-component" },
      [
        _vm.hasError
          ? _c("div", { staticClass: "error" }, [
              _vm._v("Sorry, we weren't able to fetch recent posts.")
            ])
          : [
              _vm.isLoading
                ? _c("div", { staticClass: "loading" }, [_vm._v("Loading...")])
                : [
                    _c("h2", [_vm._v("Recent posts:")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "recent-posts-list" },
                      _vm._l(_vm.posts, function(post) {
                        return _c(
                          "div",
                          { key: post.slug, staticClass: "post post-card" },
                          [
                            _vm.getFeaturedMediaSourceUrl(post)
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "post-thumbnail",
                                    attrs: { href: post.link }
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "post-thumbnail-image",
                                      style:
                                        "background-image: url(" +
                                        _vm.getFeaturedMediaSourceUrl(post) +
                                        ")"
                                    })
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "post-content" }, [
                              _c("header", [
                                _vm.getTerm(post)
                                  ? _c("div", {
                                      staticClass: "post-category",
                                      domProps: {
                                        innerHTML: _vm._s(_vm.getTerm(post))
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("a", { attrs: { href: post.link } }, [
                                  _c("h2", {
                                    staticClass: "post-title",
                                    domProps: {
                                      innerHTML: _vm._s(post.title.rendered)
                                    }
                                  })
                                ])
                              ]),
                              _vm._v(" "),
                              _c("section", {
                                staticClass: "post-excerpt",
                                domProps: {
                                  innerHTML: _vm._s(post.excerpt.rendered)
                                }
                              }),
                              _vm._v(" "),
                              _vm.getAuthor(post)
                                ? _c("footer", [
                                    _vm._v("Written by "),
                                    _c("a", {
                                      attrs: { href: _vm.getAuthor(post).link },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getAuthor(post).name
                                        )
                                      }
                                    })
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _vm.hasMorePosts
                      ? _c("button", { on: { click: _vm.loadOlderPosts } }, [
                          _vm._v("Load older posts")
                        ])
                      : _vm._e()
                  ]
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }