import $ from 'jquery'
const $win = $( window );
const $doc = $( document );

const browserDetect = () => {
	var browser = function() {
		if (browser.prototype._cachedResult)
		return browser.prototype._cachedResult;

		var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
		var isFirefox = typeof InstallTrigger !== 'undefined';
		var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || safari.pushNotification);
		var isIE = /*@cc_on!@*/false || !!document.documentMode;
		var isEdge = !isIE && !!window.StyleMedia;
		var isChrome = !!window.chrome && !!window.chrome.webstore;
		var isBlink = (isChrome || isOpera) && !!window.CSS;

		return browser.prototype._cachedResult =
			isOpera ? 'Opera' :
			isFirefox ? 'Firefox' :
			isSafari ? 'Safari' :
			isChrome ? 'Chrome' :
			isIE ? 'IE' :
			isEdge ? 'Edge' :
			isBlink ? 'Blink' :
			"Don't know";
	};

	if( browser() == 'Safari' ) {
		$( 'body' ).addClass( 'safari' );
	} else if( browser() == 'Firefox' ) {
		$( 'body' ).addClass( 'firefox' );
	} else if( browser() == 'IE' ) {
		$( 'body' ).addClass( 'ie' );
	}
};

export default browserDetect;
