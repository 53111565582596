import $ from 'jquery'
const $win = $( window );
const $doc = $( document );

const formSubmitConf = () => {
    
    $("#location-signup-form").on('submit', function (e) {
        var form_firstname = $('#location-signup-form .gfield.vl_firstname input').val();
        var form_lastname  = $('#location-signup-form .gfield.vl_lastname input').val();
        var form_email     = $('#location-signup-form .gfield.vl_email input').val();
        campaign.identify({
            firstname: form_firstname,
            lastname: form_lastname,
            email: form_email,
            //"extraData": { "internal-ID": "1234", "page": "Nashville" }
        }, function() {
            /* optional callback */
            console.log('Data: firstname = ' + form_firstname + ' & lastname = ' + form_lastname + ' & email = ' + form_email);
            return true;
        });
    });

};

export default formSubmitConf;
