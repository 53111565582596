import { render, staticRenderFns } from "./RecentPosts.vue?vue&type=template&id=042d030b&"
import script from "./RecentPosts.vue?vue&type=script&lang=js&"
export * from "./RecentPosts.vue?vue&type=script&lang=js&"
import style0 from "./RecentPosts.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/rof/src/bitbucket.org/cayennecreative/elamar-website/public/core/themes/elamar/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('042d030b')) {
      api.createRecord('042d030b', component.options)
    } else {
      api.reload('042d030b', component.options)
    }
    module.hot.accept("./RecentPosts.vue?vue&type=template&id=042d030b&", function () {
      api.rerender('042d030b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "scripts/components/vue/RecentPosts.vue"
export default component.exports