import $ from 'jquery';

const $win = $( window );
const $doc = $( document );

const addClasses = () => {
	$win.on( 'load', function() {
		if( $( '.home--intro' ).length ) {
			$( 'body' ).addClass( 'home' );
		}

		if( $( '#wpadminbar' ).length ) {
			$( 'body' ).addClass( 'wpadmin' );
		}

		$( 'body' ).addClass( 'loaded' );
	});
};

export default addClasses;