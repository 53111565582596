import $ from 'jquery';
//import sayHello from './components/dummy';
import clickEvent from './components/actionEvents/clickEvent';
import addClasses from './components/loadEvents/addClasses';
import onLoadEvents from './components/loadEvents/onLoadEvents';
import sliders from './components/modules/sliders';
import equalizeHeight from './components/modules/equalizeHeight';
import formSubmitConf from './components/modules/formSubmitConf';
import browserDetect from './components/modules/browserDetect';
import srcollHeader from './components/modules/srcollHeader';
import scrollAnimation from './components/modules/scrollAnimation';
import scrollOnPageLoad from './components/modules/scrollOnPageLoad';
import mobileInfoBar from './components/modules/mobileInfoBar';
import Vue from 'vue';

//vue components
import RecentPosts from './components/vue/RecentPosts.vue';

Vue.config.productionTip = false;
const vueContainers = document.getElementsByClassName('vue-container');
[].forEach.call(vueContainers, (container) => {
	const vueContainer = new Vue({
		el: container,
		components: {
			RecentPosts
		}
	});
});

//sayHello();
addClasses();
clickEvent();
sliders();
browserDetect();
equalizeHeight();
formSubmitConf();
srcollHeader();
scrollAnimation();
scrollOnPageLoad();
mobileInfoBar();
onLoadEvents();
