import $ from 'jquery';

const clickEvent = () => {
	$( '.btn--menu' ).on( 'click', function (e) {
		$(this).toggleClass( 'menu__active' );
		$( 'body, html' ).toggleClass( 'menu__active' );
		e.preventDefault();
	});

	$( '.section--faq .section__action h3' ).on( 'click', function (e) {
		$(this).parents( '.section--faq' ).toggleClass( 'faq__active' );

		let $faq__body = $(this).parents( '.section--faq' ).find( '.section__body' );

		if ( $faq__body.is( ':hidden' ) ) {
			$( '.section--faq' ).addClass( 'faq__active' );
			$faq__body.show( 400 );
		} else {
			$( '.section--faq' ).removeClass( 'faq__active' );
			$faq__body.hide( 400 );
		}

		e.preventDefault();
	});

	$( '.slider--tabs .tav__nav a' ).on( 'click', function (e) {
		let $tabLink = $(this);
		let $targetTab = $($tabLink.attr( 'href' ));

		$tabLink.parent().addClass( 'active_image' ).siblings().removeClass( 'active_image' );

		if ( $targetTab.is( ':hidden' ) ) {
			$targetTab.parent().find( '.tab__image' ).hide( 0 )
			$targetTab.fadeIn( 400 );
		}

		e.preventDefault();
    });
    
    $( '.click_box' ).on( 'click', function (e) {
        let $link = $(this).find('a');
        
        e.preventDefault();
        e.stopPropagation();
        if ($link.length) {
            if ('_blank' == $link.attr( 'target' ) ) {
                window.open( $link.attr( 'href' ) );
            } else {
                window.location = $link.attr( 'href' );
            }
        }
    });

    $( '.accordion-header' ).on( 'click', function (e) {
        let $header   = $(this);
        let $navHeight = $('header.header').height();

        $header.siblings('.accordion-body').slideToggle(500, function() {
            $header.toggleClass('open');
            $('html,body').animate({
                scrollTop: $header.offset().top - $navHeight
            }, 750); 
        });
        $('.accordion-header').not($header).removeClass('open').siblings('.accordion-body').slideUp(500);
        
    });

    $( '.search-form-wrapper i.fa-search' ).on( 'click', function (e) {
        e.preventDefault();

        $( '.search-form-inner' ).toggleClass('open');

        // if ( $( '.search-form-inner' ).hasClass('open') {
        //     $( '#search-form').trigger( 'submit' );
        // } else {
        //     $( '.search-form-inner' ).addClass('open');
        // }

    });

    $( '.toggle-link' ).on( 'click', function (e) {

        e.preventDefault();
        if ( !$(this).hasClass('active') ) {
            
            $(this).addClass('active');
            $( '.toggle-link' ).not(this).removeClass('active');

            let $grid = $( '.box-section.box-' + $(this).data('box') );
            $grid.fadeIn();
            $( '.box-section').not($grid).fadeOut();
        }
    });

    // Treatment Pricing Selector
    $( '#location-options' ).on( 'change', function (e) {
        $( '#location-price' ).hide().html( $(this).val() ).fadeIn(600);
    });

    // Update hidden email field for HIPAA forms routing
    $( '#input_5_9' ).on( 'change', function(){
        var location = $(this).val().toLowerCase();
        if ( $('#input_5_12').length ) {
            $('#input_5_12').val(location + '@elamarskin.com');
            console.log('location email: ' + $('#input_5_12').val() );
        }
    });

    // Landing Page Form Submits
    const $landingFormTrigger = $('#landing-form-toggle');
    const $landingFormCloser  = $('#landing-form-closer');
    const $landingFormInner   = $('#landing-form-inner');
    const $landingForm        = $('#gform_8');
    const $landingFormButton  = $('#gform_submit_button_8');
    const $landingServices    = $('.landing-treatments .single__service');

    let toggleLandingForm = function() {

        if ( $landingFormInner.hasClass('form-open') ) {
            $landingForm.trigger('submit');
        } else {
            $landingFormInner.addClass('form-open');
            $landingFormTrigger.html('Book Now');
        }

    };

    $landingFormTrigger.on('click', function() {
        toggleLandingForm();
    });

    $landingFormCloser.on('click', function() {
        $landingFormInner.removeClass('form-open');
        $landingFormTrigger.html( $landingFormButton.html() );
    });

    $landingServices.on('click', function() {
        if ( !$landingFormInner.hasClass('form-open') && $( window ).width() < 980 ) {
            $landingFormInner.addClass('form-open');
        }
    });

};

export default clickEvent;