import $ from 'jquery'
const $win = $( window );
const $doc = $( document );
var scrollPos = 0;

const srcollHeader = () => {
	$win.on( 'load scroll', function() {
		if ( $( '.home' ).length ) {
			let headerHeight = $( '.header' ).outerHeight();
            let adminHeight = $( '#wpadminbar' ).length ? $( '#wpadminbar' ).outerHeight() : 0;
			let winHeight = $win.height() - headerHeight - adminHeight;
			scrollPos = $win.scrollTop();

			if ( scrollPos > winHeight ) {
				 $( '.header' ).addClass( 'sticky' );
			} else {
				 $( '.header' ).removeClass( 'sticky' );
			}
		}
	});
};

export default srcollHeader;
