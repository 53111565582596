import $ from 'jquery';
import Swiper from 'swiper/js/swiper.min';

const $win = $( window );
const $doc = $( document );

const sliders = () => {
	$win.on( 'load resize', function() {
		$( '.home--about .swiper-slide .entry, body.page-template-sub-service .section--testimonials .swiper-slide, body.page-template-service-single .section--testimonials .swiper-slide, body.single-landing .landing-testimonials .swiper-slide' ).equalizeHeight();
	});

	$win.on( 'load', function() {
		const mySwiper = new Swiper('.home--about .swiper-container', {
			slidesPerView: 'auto',
			freeMode: true,
			freeModeMomentum: false,
			resistanceRatio: 0,
			autoHeight: true,
			scrollbar: {
				el: '.home--about .image-gallery-slider__scrollbar',
				hide: false,
				draggable: true
			},
			keyboard: {
				enabled: true,
				onlyInViewport: true
			},
			mousewheel: {
				forceToAxis: true,
				invert: true
			}, navigation: {
				nextEl: '.home--about .swiper-button-next',
				prevEl: '.home--about .swiper-button-prev'
            },
            on: {
                click: function(){
                    if (this.clickedSlide !== undefined) {
                        let $link = $(this.clickedSlide).find('a');
                        if ($link.length) {
                            window.location = $link.attr( 'href' );
                        }
                    }
                },
              }
		});

		const mySwiperTestimonials = new Swiper('.section--testimonials .swiper-container', {
            //autoplay: true,
			slidesPerView: 'auto',
			loop: true,
			resistanceRatio: 0,
			autoHeight: true,
			keyboard: {
				enabled: true,
				onlyInViewport: true
			},
			mousewheel: {
				forceToAxis: true,
				invert: true
			}, navigation: {
				nextEl: '.section--testimonials .swiper-button-next',
				prevEl: '.section--testimonials .swiper-button-prev'
			}
		});

		const mySwiperServices = new Swiper('.section--service .swiper-container', {
			slidesPerView: 'auto',
			loop: true,
			resistanceRatio: 0,
			autoHeight: true,
			keyboard: {
				enabled: true,
				onlyInViewport: true
			},
			mousewheel: {
				forceToAxis: true,
				invert: true
			}, navigation: {
				nextEl: '.section--service .swiper-button-next',
				prevEl: '.section--service .swiper-button-prev'
			}
		});

		const mySwiperTabs = new Swiper('.slider--tabs .swiper-container', {
			spaceBetween: 30,
      		effect: 'fade',
			autoHeight: true,
			keyboard: {
				enabled: true,
				onlyInViewport: true
			},
			mousewheel: {
				forceToAxis: true,
				invert: true
			}, navigation: {
				nextEl: '.slider--tabs .swiper-button-next',
				prevEl: '.slider--tabs .swiper-button-prev'
			}
		});

		const mySwiperGallery = new Swiper('.post__gallery .swiper-container', {
			slidesPerView: 'auto',
			loop: true,
			resistanceRatio: 0,
			autoHeight: true,
			keyboard: {
				enabled: true,
				onlyInViewport: true
			},
			mousewheel: {
				forceToAxis: true,
				invert: true
			}, navigation: {
				nextEl: '.post__gallery .swiper-button-next',
				prevEl: '.post__gallery .swiper-button-prev'
			}
		});

        const mySwiperLocation = new Swiper('.location-contact-gallery .swiper-container', {
			slidesPerView: 'auto',
			loop: true,
			resistanceRatio: 0,
			autoHeight: true,
			keyboard: {
				enabled: true,
				onlyInViewport: true
			},
			mousewheel: {
				forceToAxis: true,
				invert: true
			}, navigation: {
				nextEl: '.location-contact-gallery .swiper-button-next',
				prevEl: '.location-contact-gallery .swiper-button-prev'
			}
		});

        const mySwiperLanding = new Swiper('.landing-testimonials .swiper-container', {
			slidesPerView: 'auto',
			loop: true,
			resistanceRatio: 0,
			autoHeight: true,
			keyboard: {
				enabled: true,
				onlyInViewport: true
			},
			mousewheel: {
				forceToAxis: true,
				invert: true
			}, navigation: {
				nextEl: '.landing-testimonials .swiper-button-next',
				prevEl: '.landing-testimonials .swiper-button-prev'
			}
		});

	});

	$.fn.equalizeHeight = function() {
		let maxHeight = 0, itemHeight;

		this.css( 'height', '' );

		for (let i = 0; i < this.length; i++) {
			itemHeight = $(this[i]).height();
			if (maxHeight < itemHeight) {
				maxHeight = itemHeight;
			}
		}

		return this.height(maxHeight);
	}
};

export default sliders;