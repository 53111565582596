import $ from 'jquery'
const $win = $( window );
const $doc = $( document );

const scrollOnPageLoad = () => {
	$doc.ready( function() {
		if ( window.location.hash ) {
			var hash = window.location.hash.substring(1);
			let headerHeight = $( '.header' ).outerHeight();
			let adminHeight = 0;
			
			if ( $('#wpadminbar').length ) {
				adminHeight = $( '#wpadminbar' ).height();
			}

			let $targetElement = $( '[data-id="' +  hash + '"]' );

			if ( !$targetElement.length ) {
				return;
			}

			let offsetHash = $targetElement.offset().top - headerHeight - adminHeight;

			$( 'body, html' ).animate({
				scrollTop: offsetHash
			}, 500);
		}
	} );
};

export default scrollOnPageLoad;
