import $ from 'jquery';
import ScrollMagic from 'scrollmagic';
import { TweenMax } from 'gsap/TweenMax';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';

const $win = $( window );
const $doc = $( document );

const scrollAnimation = () => {
	const winH = $win.height();

	$( '.fade--section' ).each(function() {
		const $element = $(this);

		$win.on( 'scroll load', function() {
			if ( $element.offset().top <= $doc.scrollTop() + winH ) {
				setTimeout(function() {
					$element.addClass( 'visible' );
				}, 5);
			}
		});
	});

	$win.on( 'load', function() {
		setTimeout(function() {

			if ( $( '.home' ).length ) {
				let sectionHeight = $( '.video__trigger_stop' ).outerHeight();
				const controller = new ScrollMagic.Controller();
				const scene = new ScrollMagic.Scene({
					triggerElement: '.video__trigger',
					triggerHook: 0,
					duration: sectionHeight,
				})
				// set tween
				.setTween('.logo', 1, { top: '0', width: '106px', margin: '15px 0 0' } )				
				// add the scene to controller
				.addTo(controller)
				.on('progress', e => {
					if ( e.progress >= 0.9 ) {
						$( '.logo svg' ).css( 'fill', '#505050' );
					} else {
						$( '.logo svg' ).css( 'fill', '#fff' );
					}
				});

				$win.on('resize', () => {
					sectionHeight = $('.home--intro').outerHeight();
					scene['duration'](sectionHeight);
				});
			}
		}, 100);
	});
};

export default scrollAnimation;